import { getSizeStringFromCode } from "@/commons/convertion";
import { db } from "@/main";
import { NextAction } from "@/types";
import { Trigrams } from "@/types/trigrams";
import * as ExcelJS from "exceljs";
import { collection, doc, getDocs } from "firebase/firestore";

const headersGeneral = [
    "Indice",
    "Société",
    "Numéro standard",
    "Remarques BD",
    "Commande client",
    "Marché global",
    "Segment",
    "Région",
    "Taille",
    "CA",
    "Adresse postale",
    "ville",
    "CP",
    "Site internet",
    "Mail générique",
    "Prénom",
    "Nom",
    "Poste",
    "Mail",
    "Téléphone",
];

const headersContact = ["Prénom", "Nom", "Poste", "Mail", "Téléphone"];

const headersCall = [
    "Collaborateur",
    "Date",
    "N° semaine",
    "Appelé",
    "Décroché",
    "Décideur",
    "Prochaine action",
    "Etat",
    "Remarque",
    "à rappeler le",
    "Rappel agenda",
];

const headersMeeting = [
    "Date",
    "Heure",
    "Prénom",
    "Nom",
    "Poste",
    "Mail",
    "Téléphone",
];

const fieldMappingGeneral = {
    Name: "Société",
    Phone: "Numéro standard",
    Comment: "Remarques BD",
    DepartmentNumber: "Région",
    Size: "Taille",
    Turnover: "CA",
    Address: "Adresse postale",
    City: "ville",
    PostalCode: "CP",
    Website: "Site internet",
    Mail: "Mail générique",
    FirstName: "Prénom",
    LastName: "Nom",
    Position: "Poste",
};

const fieldMappingContact = {
    FirstName: "Prénom",
    LastName: "Nom",
    Position: "Poste",
    Mail: "Mail",
    Phone: "Téléphone",
};

const fieldMappingCall = {
    EmployeeId: "Collaborateur",
    Date: "Date",
    WeekNumber: "N° semaine",
    Called: "Appelé",
    PickedUp: "Décroché",
    DecisionMaker: "Décideur",
    NextAction: "Prochaine action",
    Status: "Etat",
    Comments: "Remarque",
};

const fieldMappingMeeting = {
    AppointmentDateDate: "Date",
    AppointmentDateHour: "Heure",
    FirstName: "Prénom",
    LastName: "Nom",
    Position: "Poste",
    Mail: "Mail",
    Phone: "Téléphone",
};

const transformDate = (timestamp: { seconds: number; nanoseconds: number }) => {
    if (!timestamp || typeof timestamp.seconds !== "number") {
        return "";
    }
    const date = new Date(timestamp.seconds * 1000);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

const transformHour = (timestamp: { seconds: number; nanoseconds: number }) => {
    if (!timestamp || typeof timestamp.seconds !== "number") {
        return "";
    }
    const date = new Date(timestamp.seconds * 1000);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}h${formattedMinutes}`;
};

const getInitialsFromId = (id: string) => {
    const operator = Trigrams.find((op: any) => op.id === id);
    return operator ? operator.initials : "";
};

export async function fetchAndProcessData(orderId: string) {
    try {
        const orderDocRef = doc(db, "Orders", orderId);
        const prospectsCollectionRef = collection(orderDocRef, "Prospects");

        const prospectsSnapshot = await getDocs(prospectsCollectionRef);
        const prospects = prospectsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));

        const prospectsWithDetails = await Promise.all(
            prospects.map(async (prospect) => {
                const contactsCollectionRef = collection(
                    prospectsCollectionRef,
                    prospect.id,
                    "Contacts"
                );
                const contactsSnapshot = await getDocs(contactsCollectionRef);
                const contacts = contactsSnapshot.docs.map((contactDoc) => ({
                    ...contactDoc.data(),
                }));

                const callsCollectionRef = collection(
                    prospectsCollectionRef,
                    prospect.id,
                    "Calls"
                );
                const callsSnapshot = await getDocs(callsCollectionRef);
                const calls = callsSnapshot.docs.map((callDoc) => ({
                    ...callDoc.data(),
                }));

                return { ...prospect, contacts, calls };
            })
        );

        processData(prospectsWithDetails);
    } catch (error) {
        console.error(
            "Error fetching prospects, contacts, calls, and meetings:",
            error
        );
    }
}

async function processData(prospectsWithDetails: any[]) {
    if (prospectsWithDetails.length === 0) {
        return;
    }
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("OPS");
    const headers = [...headersGeneral, ...headersContact];
    const maxCalls = Math.max(
        ...prospectsWithDetails.map((p) => p.calls.length)
    );
    for (let i = 0; i < maxCalls; i++) {
        headers.push(...headersCall.map((header) => `${header} ${i + 1}`));
    }
    headers.push(...headersMeeting);
    worksheet.columns = headers.map((header) => ({ header, key: header }));

    let counter = 1;

    prospectsWithDetails.forEach((prospect) => {
        const row: { [key: string]: any } = { Indice: counter++ };
        for (const [firestoreField, excelHeader] of Object.entries(
            fieldMappingGeneral
        )) {
            let value = prospect[firestoreField] || "";
            if (firestoreField === "Size") {
                value = getSizeStringFromCode(value);
            }
            row[excelHeader] = value;
        }

        if (prospect.contacts && prospect.contacts.length > 0) {
            const contact = prospect.contacts[0];
            for (const [firestoreField, excelHeader] of Object.entries(
                fieldMappingContact
            )) {
                row[excelHeader] = contact[firestoreField];
            }
        }

        if (prospect.calls && prospect.calls.length > 0) {
            prospect.calls.forEach((call: any, index: any) => {
                let callSetToYes = false;

                for (const [firestoreField, excelHeader] of Object.entries(
                    fieldMappingCall
                )) {
                    let value = call[firestoreField] || "";
                    if (excelHeader.includes("Date")) {
                        value = transformDate(value);
                    }
                    if (excelHeader.includes("Heure")) {
                        value = transformHour(value);
                    }
                    if (firestoreField === "EmployeeId") {
                        value = getInitialsFromId(value);
                    }
                    if (firestoreField === "PickedUp") {
                        value = value ? "Oui" : "Non";
                    }
                    if (firestoreField === "DecisionMaker") {
                        value = value ? "Oui" : "Non";
                    }
                    if (firestoreField === "NextAction") {
                        value = NextAction[value];
                    }
                    if (firestoreField === "Called") {
                        value = value ? "Oui" : "Non";
                        callSetToYes = true;
                    }

                    row[`${excelHeader} ${index + 1}`] = value;

                    if (
                        firestoreField === "NextAction" &&
                        call.NextAction === 2
                    ) {
                        for (const [
                            meetingField,
                            meetingHeader,
                        ] of Object.entries(fieldMappingMeeting)) {
                            let meetingValue = call[meetingField];
                            if (meetingHeader === "Date") {
                                meetingValue = transformDate(
                                    call.AppointmentDate
                                );
                            }
                            if (meetingHeader === "Heure") {
                                meetingValue = transformHour(
                                    call.AppointmentDate
                                );
                            }
                            row[meetingHeader] = meetingValue;
                        }
                    }
                }

                if (!callSetToYes) {
                    row[`Appelé ${index + 1}`] = "Non";
                }
            });
        } else {
            for (let i = 0; i < maxCalls; i++) {
                row[`Appelé ${i + 1}`] = "Non";
            }
        }

        worksheet.addRow(row);
    });

    worksheet.eachRow({ includeEmpty: true }, (row) => {
        row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
            const header = worksheet.getColumn(colNumber).header as string;
            if (header.startsWith("Appelé") && !cell.value) {
                cell.value = "Non";
            }
        });
    });

    await createContactSheet(workbook, prospectsWithDetails);

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Export-Ops.xlsx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

async function createContactSheet(
    workbook: ExcelJS.Workbook,
    prospectsWithDetails: any[]
) {
    const worksheet = workbook.addWorksheet("Contacts");

    const headers = ["Indice", "Société"];
    const maxContacts = Math.max(
        ...prospectsWithDetails.map((p) => p.contacts.length)
    );

    for (let i = 0; i < maxContacts; i++) {
        headers.push(
            `Prénom ${i + 1}`,
            `Nom ${i + 1}`,
            `Poste ${i + 1}`,
            `Mail ${i + 1}`,
            `Téléphone ${i + 1}`
        );
    }

    worksheet.columns = headers.map((header) => ({ header, key: header }));

    let counter = 1;

    prospectsWithDetails.forEach((prospect) => {
        const row: { [key: string]: any } = {
            Indice: counter++,
            Société: prospect.Name,
        };

        if (prospect.contacts && prospect.contacts.length > 0) {
            let contactIndex = 1;
            prospect.contacts.forEach((contact: any) => {
                if (
                    contact.FirstName ||
                    contact.LastName ||
                    contact.Position ||
                    contact.Mail ||
                    contact.Phone
                ) {
                    row[`Prénom ${contactIndex}`] = contact.FirstName || "";
                    row[`Nom ${contactIndex}`] = contact.LastName || "";
                    row[`Poste ${contactIndex}`] = contact.Position || "";
                    row[`Mail ${contactIndex}`] = contact.Mail || "";
                    row[`Téléphone ${contactIndex}`] = contact.Phone || "";
                    contactIndex++;
                }
            });
        }

        worksheet.addRow(row);
    });
}
