<template>
    <div class="container">
        <Title :title="$t('users.users')" />
        <Filters @addUser="createDialog = true" @applyFilters="applyFilters" />
        <List :users="filteredUsers" />
        <CreateUser v-model="createDialog" @close="createDialog = false" />
    </div>
</template>

<script setup lang="ts">
import { formatPhoneNumber } from "@/commons/convertion";
import Title from "@/components/customVuetify/Title.vue";
import { db } from "@/main";
import { Roles, UserDB, UserList } from "@/types";
import { collection, getDocs, onSnapshot } from "firebase/firestore";
import { computed, onMounted, ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import CreateUser from "./CreateUser.vue";
import Filters from "./Filters.vue";
import List from "./List.vue";

const { t: $t } = useI18n();

const users = ref<UserList[]>([]);
const createDialog = ref(false);
const filters = ref({});

const filteredUsers = computed(() => {
    if (!filters.value || Object.keys(filters.value).length === 0) {
        return users.value;
    }
    return users.value.filter((user: any) =>
        Object.entries(filters.value).every(([key, value]) => {
            if (key === "id") return true;
            if (
                typeof value === "string" &&
                user[key] &&
                typeof user[key] === "string"
            ) {
                return user[key].toLowerCase().includes(value.toLowerCase());
            }
            if (
                Array.isArray(value) &&
                value.length > 0 &&
                user[key] &&
                typeof user[key] === "string"
            ) {
                return value.some(
                    (v) => v.toLowerCase() === user[key].toLowerCase()
                );
            }
            if (
                Array.isArray(value) &&
                value.length > 0 &&
                user[key] &&
                typeof user[key] === "number"
            ) {
                return value.some((v) => v === user[key]);
            }
            return true;
        })
    );
});

function applyFilters(newFilters: any) {
    filters.value = newFilters;
}

let clientMap = new Map();
let superiorMap = new Map();

async function fetchClientAndSuperiorMaps() {
    const clientCollection = collection(db, "Clients");
    const superiorCollection = collection(db, "Users");

    const clientSnapshot = await getDocs(clientCollection);
    const superiorSnapshot = await getDocs(superiorCollection);

    clientSnapshot.docs.forEach((doc) => {
        clientMap.set(doc.id, doc.data());
    });

    superiorSnapshot.docs.forEach((doc) => {
        superiorMap.set(doc.id, doc.data());
    });
}

function mapUserDBtoUserList(userDB: UserDB) {
    let clientName = "";
    let superiorName = "";

    if (userDB.ClientId) {
        const client = clientMap.get(userDB.ClientId);
        if (client) {
            clientName = client.Name;
        } else {
            clientName = $t("common.error");
        }
    }

    if (userDB.SuperiorId) {
        const superior = superiorMap.get(userDB.SuperiorId);
        if (superior) {
            superiorName = `${superior.LastName} ${superior.FirstName}`;
        }
    }

    return {
        DocId: userDB.DocId,
        LastName: userDB.LastName.toUpperCase(),
        FirstName: userDB.FirstName,
        Phone: userDB.Phone ? formatPhoneNumber(userDB.Phone) : "",
        Mail: userDB.Mail,
        Role: getRoleName(userDB.Role),
        ClientName: clientName,
        SuperiorName: superiorName,
    } as any;
}

const getRoleName = (role: Roles) => {
    switch (role) {
        case 1:
            return $t("roles.admin");
        case 2:
            return $t("roles.supervisor");
        case 3:
            return $t("roles.operator");
        default:
            return "";
    }
};

onMounted(async () => {
    await fetchClientAndSuperiorMaps();

    const userCollection = collection(db, "Users");
    const unsubscribe = onSnapshot(userCollection, (snapshot) => {
        users.value = snapshot.docs.map((doc) =>
            mapUserDBtoUserList({
                DocId: doc.id,
                ...doc.data(),
            } as unknown as UserDB)
        );
    });

    watchEffect((onInvalidate) => {
        onInvalidate(() => {
            unsubscribe();
        });
    });
});
</script>
