<template>
    <v-row class="filters-padding">
        <v-col cols="2">
            <TextField
                v-model="filters.Name"
                :disableMessage="true"
                :label="$t('db.name')"
                :clearable="true"
            ></TextField>
        </v-col>
        <v-spacer />
        <v-col cols="auto">
            <v-btn
                class="button-bg-secondary"
                :text="$t('db.showAllProspects')"
                prepend-icon="mdi-database-outline"
                @click="$router.push({ name: 'allProspects' })"
            />
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
import TextField from "@/components/customVuetify/TextField.vue";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();

const emit = defineEmits(["applyFilters"]);

const filters = ref({
    Name: "",
});

onMounted(() => {
    emit("applyFilters", filters.value);
});
</script>
