<template>
    <v-overlay v-model="showOverlay" :persistent="true">
        <div class="centered-content">
            <v-card class="card-overlay">
                <v-card-title>
                    <Span style="font-weight: bold; font-size: 2rem">{{
                        $t("common.warning")
                    }}</Span>
                </v-card-title>
                <v-card-text style="font-size: 1rem">
                    <Span>{{ $t("calls.alreadyCalledText") }}</Span>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        class="button-bg-secondary"
                        @click="showOverlay = false"
                        >{{ $t("common.yes") }}</v-btn
                    >
                </v-card-actions>
            </v-card>
        </div>
    </v-overlay>
    <Transition
        :name="transitionName"
        mode="out-in"
        @after-enter="getProspectData"
        @before-enter="onBeforeEnter"
    >
        <div :key="transitionKey" class="container">
            <v-row class="title-row" justify="space-between">
                <v-col cols="auto">
                    <Span class="page-title"
                        >{{ $t("calls.ongoing") }} -
                        {{ currentProspectNumber }}/{{
                            totalProspectsNumber
                        }}</Span
                    >
                </v-col>
                <v-col cols="auto">
                    <Span class="page-title">Call {{ callNumber }}</Span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="5" class="multiple-cards">
                    <Context
                        :prospectData="prospect"
                        :orderId="orderIdForDisplay"
                    />
                    <Contacts :orderId="orderIdForDisplay" />
                </v-col>
                <v-col cols="7" class="multiple-cards">
                    <Order :orderId="orderIdForDisplay" />
                    <PersonToCall
                        :orderId="orderIdForDisplay"
                        :prospectId="prospectIdForDisplay"
                    />
                    <CallInfos
                        :orderId="orderIdForDisplay"
                        @saveCall="saveNewCall"
                    />
                    <Notepad />
                    <CallHistory
                        :orderId="orderIdForDisplay"
                        @deleted="getCallNumber"
                    />
                </v-col>
            </v-row>
        </div>
    </Transition>
    <BottomBar @update="changeState" />
</template>

<script setup lang="ts">
import { getDepartmentNumberFromPostalCode } from "@/commons/convertion";
import { getCallNumberForProspect } from "@/commons/reporting";
import { Span } from "@/components/customVuetify";
import { useSnackbar } from "@/composables/useSnackbar";
import { db } from "@/main";
import router from "@/router";
import { store } from "@/store";
import {
    departmentRegionTypeMapping,
    IdName,
    NextAction,
    Sizes,
} from "@/types";
import { defaultCallData } from "@/types/call";
import { defaultProspect } from "@/types/prospect";
import { getAuth } from "firebase/auth";
import {
    addDoc,
    collection,
    doc,
    getDocs,
    onSnapshot,
    orderBy,
    query,
    setDoc,
    updateDoc,
} from "firebase/firestore";
import { computed, onBeforeUnmount, onMounted, provide, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import BottomBar from "./BottomBar.vue";
import CallHistory from "./CallHistory.vue";
import CallInfos from "./CallInfos.vue";
import Contacts from "./Contacts.vue";
import Context from "./Context.vue";
import Notepad from "./Notepad.vue";
import Order from "./Order.vue";
import PersonToCall from "./PersonToCall.vue";

const { t: $t } = useI18n();
const route = useRoute();
const auth = getAuth();
const { showSnackbar } = useSnackbar();
const prospectId = ref(route.params.id as string);
const matchingOrderInit = store.state.prospectIds.find(
    (order: any) => order.prospectId === prospectId.value
);
const orderId = ref(matchingOrderInit?.orderId || "");
const prospectIdForDisplay = computed(() => route.params.id as string);
const orderIdForDisplay = computed(() => {
    const orderMatching = store.state.prospectIds.find(
        (order: any) => order.prospectId === prospectIdForDisplay.value
    );
    return orderMatching?.orderId || "";
});
const currentClient = ref<IdName | null>(null);
const totalProspectsNumber = store.state.prospectIds.length;
const currentProspectNumber = computed(() => {
    return store.state.currentProspectIndex + 1;
});

const callNumber = ref(1);

const showOverlay = ref(false);

let oldProspectSaved = true;

const prospect = ref(defaultProspect);

const callData = ref({ ...defaultCallData });

provide("callData", callData);

function clearInfo() {
    callData.value = { ...defaultCallData };
}

const transitionName = ref("");
const transitionKey = ref(0);

const isCurrentContactNotEmpty = computed(() => {
    return (
        callData.value.FirstName ||
        callData.value.LastName ||
        callData.value.Phone ||
        callData.value.Mail ||
        callData.value.Position
    );
});

function changeState(name: string) {
    showOverlay.value = false;
    transitionName.value = name;
    transitionKey.value++;
}

async function saveNewCall() {
    try {
        if (!auth.currentUser) {
            throw new Error("No user connected");
        }
        if (!currentClient.value) {
            throw new Error("No client selected");
        }
        callData.value.EmployeeId = auth.currentUser.uid;
        callData.value.ClientId = currentClient.value.id;
        callData.value.OrderId = orderId.value;
        callData.value.Date = new Date();
        if (
            (callData.value.NextAction === NextAction.mail &&
                !callData.value.Mail) ||
            (callData.value.NextAction === NextAction.meeting &&
                !callData.value.Mail &&
                !callData.value.Phone &&
                !callData.value.FirstName &&
                !callData.value.LastName &&
                !callData.value.RdvType &&
                !callData.value.AppointmentDate &&
                !callData.value.Comments)
        ) {
            alert($t("alerts.callNotSaved"));
            throw new Error("Mail is required for mail action");
        }
        const newCallDocRef = doc(
            db,
            "Orders",
            orderId.value,
            "Prospects",
            prospectId.value,
            "Calls",
            String(callNumber.value)
        );
        await setDoc(newCallDocRef, callData.value);

        const prospectDocRef = doc(
            db,
            "Orders",
            orderId.value,
            "Prospects",
            prospectId.value
        );
        await updateDoc(prospectDocRef, { Called: true });

        if (isCurrentContactNotEmpty.value) {
            const contactsCollection = collection(
                db,
                "Orders",
                orderId.value,
                "Prospects",
                prospectId.value,
                "Contacts"
            );

            const contactDataFetched = {
                Salutation: callData.value.Salutation,
                FirstName: callData.value.FirstName,
                LastName: callData.value.LastName,
                Phone: callData.value.Phone,
                Mail: callData.value.Mail,
                Position: callData.value.Position,
            };

            await addDoc(contactsCollection, contactDataFetched);
        }
        showSnackbar($t("calls.callSaved"), 1000, "var(--ys-green)");
        callNumber.value =
            (await getCallNumberForProspect(orderId.value, prospectId.value)) +
            1;
        clearInfo();
    } catch (error) {
        console.error("Error adding new call:", error);
    }
}

async function getCallNumber() {
    callNumber.value =
        (await getCallNumberForProspect(orderId.value, prospectId.value)) + 1;
}

async function getProspectData() {
    while (!oldProspectSaved) {
        await new Promise((resolve) => setTimeout(resolve, 100));
    }

    prospectId.value = route.params.id as string;
    const matchingOrder = store.state.prospectIds.find(
        (order: any) => order.prospectId === prospectId.value
    );
    if (matchingOrder) {
        orderId.value = matchingOrder.orderId;
    }

    if (!prospectId.value || !orderId.value) {
        return;
    }

    callNumber.value =
        (await getCallNumberForProspect(orderId.value, prospectId.value)) + 1;

    const callsSnapshot = await getDocs(
        query(
            collection(
                db,
                "Orders",
                orderId.value,
                "Prospects",
                prospectId.value,
                "Calls"
            ),
            orderBy("Date")
        )
    );
    const latestCall = callsSnapshot.docs[callsSnapshot.docs.length - 1];
    if (latestCall) {
        const latestCallDate = latestCall.data().Date.toDate();
        const today = new Date();
        if (
            latestCallDate.getDate() === today.getDate() &&
            latestCallDate.getMonth() === today.getMonth() &&
            latestCallDate.getFullYear() === today.getFullYear()
        ) {
            showOverlay.value = true;
        }
    }

    const docRef = doc(
        db,
        "Orders",
        orderId.value,
        "Prospects",
        prospectId.value.toString()
    );

    onSnapshot(docRef, (docSnap) => {
        if (!currentClient.value) return;
        if (!docSnap.exists()) {
            router.push("/404");
            return;
        }
        if (docSnap.data().ClientId === currentClient.value.id) {
            const data = docSnap.data();
            prospect.value = {
                ...prospect.value,
                ...data,
                Department:
                    departmentRegionTypeMapping[
                        getDepartmentNumberFromPostalCode(data.PostalCode)
                    ]?.department,
                Region: departmentRegionTypeMapping[
                    getDepartmentNumberFromPostalCode(data.PostalCode)
                ]?.region,
                Size:
                    Sizes.find((size) => size.id === data.Size)?.name || "N/A",
            } as any;
        } else {
            router.push("/403");
        }
    });
}

async function onBeforeEnter() {
    oldProspectSaved = false;
    if (
        callData.value.PickedUp !== null &&
        callData.value.DecisionMaker !== null &&
        callData.value.NextAction !== (0 as NextAction)
    ) {
        await saveNewCall();
    }
    callNumber.value =
        (await getCallNumberForProspect(orderId.value, prospectId.value)) + 1;
    oldProspectSaved = true;
}

watch(
    () => route,
    async () => {
        if (
            callData.value.PickedUp !== null &&
            callData.value.DecisionMaker !== null &&
            callData.value.NextAction !== (0 as NextAction)
        ) {
            await saveNewCall();
        }
    }
);

watch(
    () => store.state.currentClient,
    async (newClient) => {
        if (newClient) {
            currentClient.value = newClient;
            await getProspectData();
        }
    }
);

onMounted(async () => {
    currentClient.value = store.state.currentClient;
    await getProspectData();
});

onBeforeUnmount(async () => {
    if (
        callData.value.PickedUp !== null &&
        callData.value.DecisionMaker !== null &&
        callData.value.NextAction !== (0 as NextAction)
    ) {
        await saveNewCall();
    }
});
</script>

<style scoped>
.v-row {
    align-items: start;
}

.multiple-cards > .card:not(:last-child) {
    margin-bottom: 1rem;
}

.title-row {
    padding-left: 5vw;
    padding-right: 5vw;
    position: relative;
}

.page-title {
    font-size: 1.8rem;
    font-weight: bold;
}

.centered-content,
.card-overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
}

.card-overlay {
    padding: 12px;
}

.v-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
