<template>
    <v-row class="filters-padding-top">
        <v-col cols="auto">
            <v-btn
                class="button-bg-secondary"
                :text="$t('db.addProspect')"
                prepend-icon="mdi-file-document-plus-outline"
                @click="$emit('createProspect')"
            />
        </v-col>
        <v-col cols="auto">
            <v-btn
                class="button-bg-secondary"
                :text="$t('db.importDB')"
                prepend-icon="mdi-file-export-outline"
                @click="isImportPopupOpen = true"
            />
        </v-col>
        <v-col cols="auto">
            <v-btn
                class="button-bg-secondary"
                :text="$t('db.downloadSample')"
                prepend-icon="mdi-file-download-outline"
                @click="downloadTemplate"
            />
        </v-col>
        <v-col cols="auto">
            <v-btn
                v-if="!isEmployee"
                class="button-bg-primary"
                :text="$t('common.importOps')"
                prepend-icon="mdi-file-export-outline"
                @click="isImportPopupAdvancedOpen = true"
            />
        </v-col>
        <v-col cols="auto">
            <v-btn
                v-if="!isEmployee"
                class="button-bg-primary"
                :text="$t('db.downloadSampleOps')"
                prepend-icon="mdi-file-download-outline"
                @click="downloadTemplateOps"
            />
        </v-col>
        <v-col cols="auto">
            <v-btn
                class="button-bg-green"
                :text="$t('db.exportOPS')"
                prepend-icon="mdi-file-document-plus-outline"
                @click="fetchAndProcessData(orderId)"
            />
        </v-col>
        <v-col cols="auto">
            <v-btn
                class="button-bg-secondary"
                :text="$t('db.orderProgress')"
                prepend-icon="mdi-format-list-bulleted"
                @click="progressPopup = true"
            />
        </v-col>
        <v-spacer />
        <v-col cols="auto">
            <v-btn
                class="button-bg-secondary"
                :text="$t('db.returnToOrders')"
                prepend-icon="mdi-arrow-left"
                @click="$router.push({ name: 'db' })"
            />
        </v-col>
    </v-row>
    <v-row class="filters-padding">
        <v-col cols="2">
            <TextField
                v-model="filters.Name"
                :disableMessage="true"
                :label="$t('db.name')"
                :clearable="true"
            ></TextField>
        </v-col>
        <v-col cols="2">
            <TextField
                v-model="filters.Address"
                :disableMessage="true"
                :label="$t('db.address')"
                :clearable="true"
            ></TextField>
        </v-col>
        <v-col cols="2">
            <TextField
                v-model="filters.City"
                :disableMessage="true"
                :label="$t('db.city')"
                :clearable="true"
            ></TextField>
        </v-col>
        <v-col cols="2">
            <TextField
                v-model="filters.PostalCode"
                :disableMessage="true"
                :label="$t('db.postalCode')"
                :clearable="true"
            ></TextField>
        </v-col>
        <v-col cols="2">
            <ApeField
                v-model="filters.APECode"
                :label="$t('db.apeCode')"
                :disableMessage="true"
            />
        </v-col>
        <v-spacer />
        <v-col cols="auto">
            <Span style="font-weight: bold">{{ prospectsNumber }}&nbsp;</Span
            ><Span>{{ $t("db.prospectsInDB") }}</Span>
        </v-col>
    </v-row>
    <ImportPopup
        v-model="isImportPopupOpen"
        @close="isImportPopupOpen = false"
        @importSuccess="
            showSnackbar($t('db.importSuccessMessage'), 3000, 'var(--ys-green)')
        "
        @importFail="
            showSnackbar($t('db.importFailedMessage'), 3000, 'var(--ys-red)')
        "
    />
    <ImportPopupAdvanced
        v-model="isImportPopupAdvancedOpen"
        @close="isImportPopupAdvancedOpen = false"
        @importSuccess="
            showSnackbar(
                $t('db.importOpsSuccessMessage'),
                3000,
                'var(--ys-green)'
            )
        "
        @importFail="
            showSnackbar($t('db.importFailedMessage'), 3000, 'var(--ys-red)')
        "
    />
    <OrderProgressPopup
        v-model="progressPopup"
        :orderId="orderId"
        @close="progressPopup = false"
    />
</template>

<script setup lang="ts">
import ApeField from "@/components/ApeField.vue";
import { Span } from "@/components/customVuetify";
import TextField from "@/components/customVuetify/TextField.vue";
import OrderProgressPopup from "@/components/OrderProgress/OrderProgressPopup.vue";
import { useSnackbar } from "@/composables/useSnackbar";
import { getAuth, getIdTokenResult } from "firebase/auth";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { fetchAndProcessData } from "./ExportOPS";
import ImportPopup from "./ImportPopup.vue";
import ImportPopupAdvanced from "./ImportPopupAdvanced.vue";

const { t: $t } = useI18n();

const emit = defineEmits(["applyFilters", "createProspect"]);

const route = useRoute();
const orderId = ref(route.params.id as string);

const filters = ref({
    Name: "",
    Address: "",
    City: "",
    PostalCode: "",
    APECode: undefined,
});

defineProps<{
    prospectsNumber: number;
}>();

const isImportPopupOpen = ref(false);
const isImportPopupAdvancedOpen = ref(false);
const progressPopup = ref(false);

const { showSnackbar } = useSnackbar();

const auth = getAuth();
const idTokenResult = auth.currentUser
    ? await getIdTokenResult(auth.currentUser)
    : null;

const isEmployee = computed(() => {
    return idTokenResult?.claims?.operator ? true : false;
});

function downloadTemplate() {
    const link = document.createElement("a");
    link.href = "/excels/TemplateExcel.xlsx";
    link.download = "Template-Import-Prospect.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

function downloadTemplateOps() {
    const link = document.createElement("a");
    link.href = "/excels/TemplateExcelOps.xlsx";
    link.download = "Template-Import-Prospect-Ops.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

onMounted(() => {
    emit("applyFilters", filters.value);
});
</script>
