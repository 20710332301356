<template>
    <List
        :items="orders"
        :headers="headers"
        noClick
        :sort-by="[{ key: 'Name', order: 'asc' }]"
        :showButton="true"
    >
        <template #button="{ item }">
            <v-btn
                class="button-bg-secondary"
                :text="$t('db.addProspects')"
                prepend-icon="mdi-database-outline"
                @click.stop="$emit('buttonClicked', item)"
            />
        </template>
    </List>
</template>

<script setup lang="ts">
import List from "@/components/customVuetify/List.vue";
import { Order } from "@/types";
import { DocumentData } from "firebase/firestore";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();

defineProps({
    orders: Array as () => Order[] | DocumentData[],
});

const headers = [
    { title: $t("db.orderName"), value: "Name" },
    { title: $t("db.prospectNumber"), value: "TotalNumber" },
    { title: $t("db.alreadyCalled"), value: "AlreadyCalled" },
    { title: $t("db.leftToCall"), value: "LeftToCall" },
    { title: $t("db.totalRdv"), value: "TotalRdv" },
    { title: $t("db.mailsToSend"), value: "TotalMail" },
    { title: $t("db.prospectsKo"), value: "TotalKo" },
];
</script>
