<template>
    <Dialog
        :title="$t('extracts.meetingDetails')"
        width="75vw"
        :isLoading="isDataLoading"
        :isActions="false"
        @close="close"
    >
        <template #text>
            <v-row>
                <v-col cols="4">
                    <CardTextField
                        noTopPadding
                        textArea
                        fieldSize="8"
                        label="extracts.firstName"
                        v-model="meeting.FirstName"
                        :readonly="readonly"
                    />
                </v-col>
                <v-col cols="4">
                    <CardTextField
                        noTopPadding
                        textArea
                        fieldSize="8"
                        label="extracts.lastName"
                        v-model="meeting.LastName"
                        :readonly="readonly"
                    />
                </v-col>
                <v-col cols="4">
                    <CardTextField
                        noTopPadding
                        textArea
                        fieldSize="8"
                        label="extracts.phone"
                        v-model="meeting.Phone"
                        :readonly="readonly"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <CardTextField
                        noTopPadding
                        textArea
                        fieldSize="8"
                        label="extracts.mail"
                        v-model="meeting.Mail"
                        :readonly="readonly"
                    />
                </v-col>
                <v-col cols="4">
                    <CardTextField
                        noTopPadding
                        textArea
                        fieldSize="8"
                        label="extracts.position"
                        v-model="meeting.Position"
                        :readonly="readonly"
                    />
                </v-col>
                <v-col cols="4">
                    <CardTextField
                        noTopPadding
                        textArea
                        fieldSize="8"
                        label="extracts.dateTaken"
                        v-model="meeting.DateTaken"
                        :readonly="true"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <CardTextField
                        noTopPadding
                        fieldSize="8"
                        label="extracts.meetingDate"
                        :modelValue="meeting.Date"
                        @update:modelValue="(val) => (meeting.Date = val)"
                        :readonly="readonly"
                        :disabled="meeting.DateToBeDefined"
                        type="date"
                    />
                </v-col>
                <v-col cols="4">
                    <CardTextField
                        noTopPadding
                        fieldSize="8"
                        label="extracts.meetingHour"
                        v-model="meeting.Hour"
                        :readonly="readonly"
                        :disabled="meeting.DateToBeDefined"
                        type="time"
                    />
                </v-col>
                <v-col cols="4">
                    <v-row justify="space-between" style="padding: 0">
                        <v-col cols="auto" style="margin-bottom: 22px">
                            <Span>{{ $t("calls.dateToBeDefined") }}</Span>
                        </v-col>
                        <v-col cols="auto" style="margin-bottom: 22px">
                            <RadioButton
                                class="mr-4"
                                :text="$t('calls.yes')"
                                :selected="meeting.DateToBeDefined"
                                :readonly="readonly"
                                @click="meeting.DateToBeDefined = true"
                            />
                            <RadioButton
                                :text="$t('calls.no')"
                                :selected="!meeting.DateToBeDefined"
                                :readonly="readonly"
                                @click="meeting.DateToBeDefined = false"
                            />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <CardTextField
                        noTopPadding
                        textArea
                        fieldSize="8"
                        label="extracts.employee"
                        v-model="meeting.Employee"
                        :readonly="true"
                    />
                </v-col>
                <v-col cols="4">
                    <CardTextField
                        noTopPadding
                        fieldSize="8"
                        textArea
                        label="extracts.salesman"
                        v-model="meeting.Salesman"
                        :readonly="readonly"
                    />
                </v-col>
                <v-col cols="4">
                    <v-row style="padding: 0">
                        <v-col cols="4" style="margin-bottom: 22px">
                            <Span>{{ $t("extracts.type") }}</Span>
                        </v-col>
                        <v-col cols="8">
                            <SelectField
                                v-model="meeting.Type"
                                :items="rdvTypeItems"
                                :label="$t('calls.rdvType')"
                                item-title="title"
                                item-value="value"
                                :returnObject="false"
                                :readonly="readonly"
                            />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="8">
                    <CardTextField
                        pbt0
                        fieldSize="10"
                        label="extracts.comment"
                        v-model="meeting.Comments"
                        fullRow
                        textArea
                        :readonly="readonly"
                    />
                </v-col>
                <v-col cols="4">
                    <CardTextField
                        noTopPadding
                        fieldSize="8"
                        textArea
                        label="extracts.order"
                        v-model="meeting.Order"
                        :readonly="true"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <CardTextField
                        noTopPadding
                        fieldSize="8"
                        textArea
                        label="extracts.company"
                        v-model="meeting.Company"
                        :readonly="true"
                    />
                </v-col>
                <v-col cols="4">
                    <CardTextField
                        noTopPadding
                        fieldSize="8"
                        textArea
                        label="extracts.website"
                        v-model="meeting.Website"
                        :readonly="true"
                    />
                </v-col>
                <v-col cols="4">
                    <v-row style="padding: 0">
                        <v-col cols="4" style="margin-bottom: 22px">
                            <Span>{{ $t("extracts.size") }}</Span>
                        </v-col>
                        <v-col cols="8">
                            <SizeField
                                v-model="meeting.SizeCode"
                                :label="$t('extracts.size')"
                                :readonly="true"
                            />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <CardTextField
                        noTopPadding
                        fieldSize="8"
                        label="extracts.turnover"
                        v-model="meeting.Turnover"
                        :readonly="true"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-spacer />
                <v-col cols="4" class="text-end">
                    <v-btn
                        v-if="readonly"
                        class="button-bg-white"
                        @click="readonly = false"
                        prependIcon="mdi-pencil-outline"
                        :text="$t('common.modifyInfos')"
                    />
                    <v-btn
                        v-else
                        class="button-bg-secondary"
                        :loading="isLoading"
                        @click="updateData"
                        prependIcon="mdi-check"
                        :text="$t('common.validateModifications')"
                    />
                </v-col>
            </v-row>
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import Dialog from "@/components/customVuetify/Dialog.vue";
import { db } from "@/main";
import { rdvTypes } from "@/types";
import { doc, updateDoc } from "firebase/firestore";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { Span } from "./customVuetify";
import CardTextField from "./customVuetify/CardTextField.vue";
import RadioButton from "./customVuetify/RadioButton.vue";
import SelectField from "./customVuetify/SelectField.vue";
import SizeField from "./SizeField.vue";

const { t: $t } = useI18n();

const readonly = ref(true);
const isLoading = ref(false);
const isDataLoading = ref(false);

const emit = defineEmits(["close"]);

const props = defineProps({
    meetingProps: {
        type: Object,
        required: false,
        default: () => ({}),
    },
});

const meeting = ref<any>({});

function getRdvTypeNumber(rdvTypeString: string): number | null {
    const key = Object.keys(rdvTypes).find(
        (key) => rdvTypes[parseInt(key, 10)] === rdvTypeString
    );
    return key ? parseInt(key, 10) : null;
}

watch(
    () => props.meetingProps,
    (newVal) => {
        meeting.value = { ...newVal };
        meeting.value.Type = getRdvTypeNumber(meeting.value.Type);
        if (meeting.value.Date) {
            const [day, month, year] = meeting.value.Date.split("/");
            meeting.value.Date = `${year}-${month}-${day}`;
        }
    },
    { immediate: true }
);

const rdvTypeItems = Object.entries(rdvTypes).map(([value, title]) => ({
    title,
    value: parseInt(value),
}));

function close() {
    readonly.value = true;
    isLoading.value = false;
    emit("close");
}

async function updateData() {
    isLoading.value = true;
    const docRef = doc(
        db,
        "Orders",
        meeting.value.OrderId,
        "Prospects",
        meeting.value.ProspectId,
        "Calls",
        meeting.value.DocId
    );

    let appointmentDate;
    if (meeting.value.Date && meeting.value.Hour) {
        const dateParts = meeting.value.Date.split("-");
        const timeParts = meeting.value.Hour.split(":");
        appointmentDate = new Date(
            parseInt(dateParts[0]),
            parseInt(dateParts[1]) - 1,
            parseInt(dateParts[2]),
            parseInt(timeParts[0]),
            parseInt(timeParts[1])
        );
    }

    await updateDoc(docRef, {
        FirstName: meeting.value.FirstName,
        LastName: meeting.value.LastName,
        Phone: meeting.value.Phone,
        Mail: meeting.value.Mail,
        Position: meeting.value.Position,
        RdvType: meeting.value.Type,
        Salesman: meeting.value.Associate,
        Comments: meeting.value.Comments,
        DateToBeDefined: meeting.value.DateToBeDefined,
        AppointmentDate: appointmentDate ? appointmentDate : null,
    });
    isLoading.value = false;
    readonly.value = true;
}
</script>

<style scoped>
.v-row {
    height: 100% !important;
}

.v-col {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
</style>
