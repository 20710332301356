<template>
    <Dialog
        :title="$t('teams.edit')"
        width="55vw"
        height="auto"
        :isLoading="isDataLoading"
        @submit="editTeam"
        @close="$emit('close')"
    >
        <template #text>
            <v-row class="top-row justify-space-between d-flex">
                <v-col cols="auto" class="pt-0 pb-0">
                    <Span>{{ $t("teams.client") }}</Span>
                </v-col>
                <v-col cols="auto" class="pt-0 pb-0">
                    <Span class="bold">{{ client.Name }}</Span>
                </v-col>
            </v-row>
            <v-row class="justify-space-between d-flex" style="height: auto">
                <v-col cols="auto" class="pt-0 pb-0">
                    <Span>{{ $t("teams.chooseEmployee") }}</Span>
                </v-col>
                <v-col cols="4" class="pt-0 pb-0">
                    <AutocompleteField
                        v-model="selectedEmployees"
                        :items="employees"
                        item-title="name"
                        item-value="id"
                        :label="$t('teams.employees')"
                        multiple
                        return-object
                        :disableMessage="true"
                    />
                </v-col>
            </v-row>
            <v-row class="top-row d-flex flex-wrap">
                <v-col cols="auto" class="pt-0">
                    <Span>{{ $t("teams.assignedEmployees") }}</Span>
                </v-col>
                <v-col class="pt-0 flex-grow-1 d-flex flex-wrap justify-end">
                    <div
                        v-for="(employee, index) in selectedEmployees"
                        :key="employee.id"
                        class="d-flex align-center mb-2"
                    >
                        <Span>{{ employee.name }}</Span>
                        <v-btn
                            class="button-bg-white close-btn ml-2"
                            icon="mdi-close"
                            @click="removeEmployee(index)"
                        />
                    </div>
                </v-col>
            </v-row>
        </template>
        <template #actions>
            <v-row justify="center">
                <v-col cols="auto" class="d-flex justify-center pt-0 pb-0">
                    <v-btn
                        class="button-bg-secondary"
                        type="submit"
                        :loading="isLoading"
                        :disabled="isLoading"
                        prependIcon="mdi-check"
                        :text="$t('common.edit')"
                    />
                </v-col>
            </v-row>
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import { getYSTeamIdName } from "@/commons/firebase";
import { Span } from "@/components/customVuetify";
import AutocompleteField from "@/components/customVuetify/AutocompleteField.vue";
import Dialog from "@/components/customVuetify/Dialog.vue";
import { db } from "@/main";
import { Client, IdName } from "@/types";
import {
    arrayRemove,
    arrayUnion,
    collection,
    doc,
    getDoc,
    getDocs,
    query,
    updateDoc,
    where,
} from "firebase/firestore";
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();

const emit = defineEmits(["close"]);

const props = defineProps({
    clientId: {
        type: String,
        required: true,
    },
});

const employees = await getYSTeamIdName();
const selectedEmployees = ref<IdName[]>([]);

const client = ref<Partial<Client>>({
    Name: "",
});

const isLoading = ref(false);
const isDataLoading = ref(false);
const clientDocId = ref("");

function removeEmployee(index: number) {
    selectedEmployees.value.splice(index, 1);
}

async function editTeam() {
    try {
        isLoading.value = true;

        for (const employee of employees) {
            const userDoc = doc(db, "Users", employee.id);
            if (selectedEmployees.value.some((e) => e.id === employee.id)) {
                await updateDoc(userDoc, {
                    AssignedClients: arrayUnion(props.clientId),
                });
            } else {
                await updateDoc(userDoc, {
                    AssignedClients: arrayRemove(props.clientId),
                });
            }
        }

        emit("close");
    } catch (error) {
        console.error("Error editing team: ", error);
    } finally {
        isLoading.value = false;
    }
}

async function getData() {
    isDataLoading.value = true;
    const clientDoc = doc(db, "Clients", props.clientId);
    const clientSnapshot = await getDoc(clientDoc);
    if (clientSnapshot.exists()) {
        clientDocId.value = clientSnapshot.id;
        client.value = {
            Name: clientSnapshot.data().Name,
        };
        const userSnapshot = await getDocs(
            query(
                collection(db, "Users"),
                where("AssignedClients", "array-contains", props.clientId)
            )
        );
        selectedEmployees.value = userSnapshot.docs.map((doc) => ({
            id: doc.id,
            name: doc.data().FirstName + " " + doc.data().LastName,
        }));
    }
    isDataLoading.value = false;
}

watch(
    [props],
    async ([newProps]) => {
        if (newProps.clientId) {
            await getData();
        }
    },
    { deep: true }
);

onMounted(async () => {
    if (props.clientId) {
        await getData();
    }
});
</script>

<style scoped>
.top-row {
    height: auto !important;
}

.close-btn {
    border: transparent !important;
    height: 28px !important;
    width: 18px !important;
}
</style>
