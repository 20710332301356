<template>
    <Dialog
        :title="$t('orders.orderProgress')"
        width="70%"
        :isLoading="!order"
        @submit="save"
        @close="close"
    >
        <template #text>
            <v-row class="pb-0">
                <v-col cols="auto" class="pt-0">
                    <Span class="title">{{
                        `${order.Name} - ${$t(
                            convertState(order.State)?.name || "common.error"
                        )}`
                    }}</Span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    <TextField
                        v-model="apeCodesString"
                        :label="$t('orders.apeCode(s)')"
                        :disableMessage="true"
                        readonly
                    />
                </v-col>
                <v-col cols="6">
                    <TextField
                        v-model="order.Turnover"
                        :disableMessage="true"
                        :label="$t('orders.turnover')"
                        readonly
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    <TextArea
                        v-model="order.Regions"
                        :label="$t('orders.regions')"
                        :disableMessage="true"
                        readonly
                    />
                </v-col>
                <v-col cols="6">
                    <TextArea
                        v-model="order.Sizes"
                        :disableMessage="true"
                        :label="$t('orders.sizes')"
                        readonly
                    />
                </v-col>
            </v-row>
            <v-divider />
            <v-row class="pb-0">
                <v-col cols="12">
                    <Span class="title">{{ $t("orders.newProgress") }}</Span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    <AutocompleteField
                        v-model="orderProgress.Departments"
                        :items="availableDepartments"
                        item-value="id"
                        item-text="name"
                        :label="$t('orders.departments')"
                        :disableMessage="true"
                        multiple
                        clearable
                    />
                </v-col>
                <v-col cols="6">
                    <TextField
                        v-model="orderProgress.Website"
                        :disableMessage="true"
                        :label="$t('orders.website')"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <TextArea
                        v-model="orderProgress.Comments"
                        :disableMessage="true"
                        :label="$t('orders.comments')"
                    />
                </v-col>
            </v-row>
            <v-divider />
            <ProgressHistory class="pt-3" :orderId="props.orderId" />
        </template>
        <template #actions>
            <div class="center-content">
                <v-btn
                    type="submit"
                    class="button-bg-secondary"
                    :text="$t('common.save')"
                    prepend-icon="mdi-content-save-outline"
                />
            </div>
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import { convertState, getSizeStringFromCode } from "@/commons/convertion";
import { getOrderFromId } from "@/commons/firebase";
import {
    AutocompleteField,
    Span,
    TextArea,
    TextField,
} from "@/components/customVuetify";
import Dialog from "@/components/customVuetify/Dialog.vue";
import { useSnackbar } from "@/composables/useSnackbar";
import { db } from "@/main";
import { departmentRegionTypeMapping } from "@/types";
import { getAuth } from "firebase/auth";
import { addDoc, collection } from "firebase/firestore";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import ProgressHistory from "./ProgressHistory.vue";

const { t: $t } = useI18n();

const order = ref<any>();
const { showSnackbar } = useSnackbar();

const props = defineProps({
    orderId: {
        type: String,
        required: true,
    },
});

const auth = getAuth();
const currentUser = auth.currentUser;

const orderProgress = ref({
    Date: new Date(),
    Website: "",
    Departments: [],
    Comments: "",
    UserId: currentUser?.uid,
});

const availableDepartments = ref<{ id: string; name: string }[]>([]);

const apeCodesString = computed(() => {
    return order.value.ApeCodes.join(", ");
});

function close() {
    orderProgress.value = {
        Date: new Date(),
        Website: "",
        Departments: [],
        Comments: "",
        UserId: "",
    };
    emit("close");
}

const emit = defineEmits(["close"]);

async function save() {
    try {
        const orderProgressCollectionRef = collection(
            db,
            "Orders",
            order.value.DocId,
            "OrderProgress"
        );
        await addDoc(orderProgressCollectionRef, orderProgress.value);
        showSnackbar($t("orders.newProgressSaved"), 3000, "var(--ys-green)");
        close();
    } catch (error) {
        console.error(error);
    }
}

onMounted(async () => {
    if (props.orderId) {
        const orderDoc = await getOrderFromId(props.orderId);
        order.value = orderDoc.data();
        order.value.DocId = orderDoc.id;
        order.value.Sizes = order.value.Sizes.map((sizeCode: string) =>
            getSizeStringFromCode(sizeCode)
        );
        const regions = order.value.Departments.map((department: any) => {
            return departmentRegionTypeMapping[department.DepartmentNumber]
                .region;
        });
        order.value.Regions = Array.from(new Set(regions)).join(", ");
        availableDepartments.value = order.value.Departments.map(
            (department: any) => {
                return {
                    id: department.DepartmentNumber,
                    name: departmentRegionTypeMapping[
                        department.DepartmentNumber
                    ].department,
                };
            }
        );
    }
});
</script>

<style scoped>
.v-row {
    height: auto !important;
}

.title {
    font-size: 1.3rem;
    font-weight: bold;
}
</style>
