<template>
    <v-row class="filters-padding-top">
        <v-spacer />
        <v-col cols="auto">
            <v-btn class="button-bg-white" @click="emit('addUser')"
                ><template v-slot:prepend>
                    <v-icon>mdi-plus</v-icon>
                </template>
                {{ $t("users.add") }}</v-btn
            >
        </v-col>
    </v-row>
    <v-row class="filters-padding">
        <v-col cols="2">
            <TextField
                class="search-field"
                v-model="filters.lastName"
                :label="$t('users.lastname')"
                :disableMessage="true"
            ></TextField>
        </v-col>
        <v-col cols="2">
            <TextField
                class="search-field"
                v-model="filters.firstName"
                :label="$t('users.firstname')"
                :disableMessage="true"
            ></TextField>
        </v-col>
        <v-col cols="2">
            <AutocompleteField
                class="search-field"
                v-model="filters.clientName"
                :items="clients"
                :label="$t('users.client')"
                item-title="name"
                item-value="name"
                :disableMessage="true"
                clearable
                multiple
            ></AutocompleteField>
        </v-col>
        <v-col cols="2">
            <AutocompleteField
                class="search-field"
                v-model="filters.role"
                :items="roleItems"
                :label="$t('users.role')"
                item-title="name"
                item-value="name"
                :disableMessage="true"
                clearable
                multiple
            ></AutocompleteField>
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
import { getClientList } from "@/commons/firebase";
import AutocompleteField from "@/components/customVuetify/AutocompleteField.vue";
import TextField from "@/components/customVuetify/TextField.vue";
import { IdName, Roles } from "@/types";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();

const emit = defineEmits(["addUser", "applyFilters"]);

const filters = ref({
    lastName: "",
    firstName: "",
    role: null,
    clientName: null,
});

const roleItems = [
    { id: Roles.admin, name: $t("roles.admin") },
    { id: Roles.supervisor, name: $t("roles.supervisor") },
    { id: Roles.operator, name: $t("roles.operator") },
];

const clients = ref<IdName[]>([]);

onMounted(async () => {
    const clientList = await getClientList();
    clients.value = clientList.map((client: any) => ({
        id: client.DocId,
        name: client.Name,
    }));
    emit("applyFilters", filters.value);
});
</script>
